import React from "react"
import { Link, graphql } from "gatsby";
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogFooter from '../components/blog-footer';
import Img from 'gatsby-image';
import { PostContainer, ArchiveButton } from '../pages/index';
import { MoreIcon, TagIcon, CameraIcon, LocationIcon, CalendarIcon } from '../components/svgs';

const PostNav = styled.nav`
  width: 100%;
  margin-bottom: 5%;
  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  li {
    display: inline;
    /* font-size: 1.4em; */
    text-transform: uppercase;
    letter-spacing: 1px;
    max-width: 50%;
    &.right {
      @media (max-width: 825px) {
        text-align: right;
      }
    }
  }
`;

export const SinglePostContainer = styled(PostContainer)`
  min-height: 70vh;
  margin-bottom: 0;
  .text {
    grid-column: ${props => props.format === 'portrait' ? '2 / 3' : '1 / 3'} !important;
    margin: ${props => props.format === 'portrait' ? '0 10%' : '0'};
    @media (max-width: 825px) {
      grid-column: 1 / 2 !important;
      grid-row: 2 / 3;
      margin: 0;
    }
  }
  .image-container {
    grid-column: ${props => props.format === 'portrait' ? '1 / 2' : '1 / 3'} !important;
    @media (max-width: 825px) {
      grid-column: 1 / 2 !important;
      grid-row: 1 / 2;
      margin: 0;
    }
  }
  h1 {
    font-size: 6em;
    @media (max-width: 440px) {
      font-size: 4em;
    }
  }
  h2 {
    font-size: 4em;
    margin: 1.2em 0;
  }
  h3 {
    font-size: 3em;
  }
  .secondary {
    display: ${props => props.format === 'portrait' ? 'grid' : 'flex'};
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 15px auto;
    grid-column-gap: 15px;
    grid-row-gap: 0.8em;
    font-size: 1.4em;

    @media (max-width: 755px) {
      margin-top: 1em;
      display: grid;
      grid-template-columns: 15px auto;
      grid-column-gap: 15px;
      grid-row-gap: 0.8em;
    }
  }
  .post-body {
    grid-column: 1 / 3;
    margin: 2.8em 0;
    color: var(--light70);
    .gallery-two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2em;
    }
    .gallery-three {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 2em;
    }
    .gallery-four {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 2em;
    }
    @media (max-width: 585px) {
      .gallery-two, .gallery-three, .gallery-four {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
      }
    }
    p {
      font-size: 2.2em;
      line-height: 1.4;
      margin-bottom: 1.4em;
    }
    blockquote {
      margin: 2em 8%;
      font-family: 'Crimson Text';
      color: var(--light60);
    }
    a {
      color: var(--light70);
      &:hover {
        color: var(--light100);
        transition: all 0.2s ease-in-out;
      }
    }
    .gatsby-resp-image-wrapper {
      margin: 2em auto;
    }
  }
  .icon-container {
    width: 20px;
    margin-left: ${props => props.format === 'portrait' ? '0' : '15px'};
    margin-right: 10px;
    &.first {
      margin-left: 0;
    }
    @media (max-width: 755px) {
      width: 15px;
      grid-column: 1 / 2;
      margin-left: 0;
    }
  }
  .tags {
    grid-column: 1 / 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--light60);
    font-size: 1.4em;
    line-height: 1.5;
    a {
      color: var(--light60);
    }
    ul {
      margin-right: 20px;
    }
    li {
      display: inline;
      list-style-type: none;
      text-transform: uppercase;
      &:after {
        content: ' · ';
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }
`;

export const DividerContainer = styled.div`
  margin: 20px auto 20px auto;
  width: 50px;
  @media (max-width: 585px) {
    margin: 40px auto 40px auto;
    width: 30px;
  }
`;

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const group = this.props.data.allMarkdownRemark.group

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords={[`photography`, `photos`, ...post.frontmatter.tags, ...post.frontmatter.keywords]}
        />

        { /* POST CONTENT */ }
        
        <SinglePostContainer format={post.frontmatter.featuredImage.childImageSharp.fluid.aspectRatio >= 1 ? 'landscape' : 'portrait'}>
          <div className="image-container">
            { post.frontmatter.featuredImage && <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} format={post.frontmatter.featuredImage.childImageSharp.fluid.aspectRatio >= 1 ? 'landscape' : 'portrait'}/> }
          </div>
          <div className="text">
            <h1>{post.frontmatter.title}</h1>
            <div className="secondary">
              <div className="icon-container first"><LocationIcon fill={ 'var(--light60)' } /></div>
              { post.frontmatter.location }
              <div className="icon-container"><CameraIcon fill={ 'var(--light60)' } /></div>
              Taken { post.frontmatter.photoDate }
              <div className="icon-container"><CalendarIcon fill={ 'var(--light60)' } /></div>
              Posted { post.frontmatter.date }
            </div>
          </div>
          <div className="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />
          <div className="tags">
            <div className="icon-container first"><TagIcon fill={ 'var(--light60)' } /></div>
            <ul>{ post.frontmatter.tags.map(tag => <li><Link to={`/blog/tags/${kebabCase(tag)}`}>{ tag }</Link></li>) }</ul>
          </div>
        </SinglePostContainer>
        <DividerContainer>
          <MoreIcon fill={ 'var(--light70' } />
        </DividerContainer>

        { /* POST NAVIGATION */ }

        <PostNav>
          <ul>
            <li>
              {previous && (
                <Link to={`blog/${previous.fields.slug}`} rel="prev">
                  <ArchiveButton>
                    ← {previous.frontmatter.title}
                  </ArchiveButton>
                </Link>
              )}
            </li>
            <li className="right">
              {next && (
                <Link to={`blog/${next.fields.slug}`} rel="next">
                  <ArchiveButton>
                    {next.frontmatter.title} →
                  </ArchiveButton>
                </Link>
              )}
            </li>
          </ul>
        </PostNav>

        { /* BLOG FOOTER */ }
        <BlogFooter group={group} chrissy={this.props.data.chrissy}/>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    chrissy: file(relativePath: { eq: "chrissy-portfolio.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        photoDate(formatString: "MMMM DD, YYYY")
        description
        location
        tags
        keywords
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
