import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

// Components
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { startCase, toLower } from 'lodash';
import { ArchiveStyles, PostContainer, OverlayDividerContainer } from '../pages/archive';
import BlogFooter from '../components/blog-footer';
import { ArchiveLinkFooter, ArchiveButton } from '../pages/index';
import { MoreIcon } from '../components/svgs';

const TagH1 = styled.h1`
  text-transform: capitalize;
  @media (max-width: 825px) {
  }
`;

const TagStyles = styled(ArchiveStyles)`
  min-height: 60vh;
`;

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark
  const { group } = data.allTags

  return (
    <Layout>
      <SEO
        title={ startCase(toLower(tag)) }
        keywords={[`blog`, `photography`, `photos`, `react`, `gatsby`, `nyc`, `new york city`, `brooklyn`]}
      />
      <TagStyles>
        <div className="heading">
          <TagH1>{tag}</TagH1>
        </div>
          <div className="group">
            {edges.map(({ node }) => {
              const { slug } = node.fields
              const { title } = node.frontmatter
              return (
                <PostContainer key={slug}>
                  <Link to={`/blog/${slug}`}>
                    <div className="image-container">
                      <Img 
                        sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
                        alt={title}
                        className="card-img"
                      />
                    </div>
                    <div className="text-overlay">
                      <h1>{title || node.fields.slug}</h1>
                      <OverlayDividerContainer>
                        <MoreIcon fill={ 'var(--light70' } />
                      </OverlayDividerContainer>
                      <p className="date">{ node.frontmatter.date }</p>
                    </div>
                  </Link>
                </PostContainer>
              )
            })}
          </div>
      </TagStyles>
      <ArchiveLinkFooter>
        <Link to='archive'><ArchiveButton>View All Posts</ArchiveButton></Link>
      </ArchiveLinkFooter>
      <BlogFooter group={group} chrissy={data.chrissy}/>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              description: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    chrissy: file(relativePath: { eq: "chrissy-portfolio.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    allTags: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 200, maxHeight: 200, quality: 100) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`