import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogFooter from '../components/blog-footer';
import styled from 'styled-components';
import { uniq } from 'lodash';
import { MoreIcon } from '../components/svgs';
import { DividerContainer } from '../templates/blog-post';

export const ArchiveStyles = styled.div`
  width: 100%;
  margin-bottom: 8%;
  @media (max-width: 585px) {
    margin-bottom: 0;
  }
  a {
    border: none;
  }
  h1 {
    font-size: 7em;
    @media (max-width: 825px) {
      font-size: 5em;
    }
  }
  .heading {
    margin: 100px 0 40px 0;
    @media (max-width: 825px) {
      margin-top: 50px;
      text-align: center;
    }
    @media (max-width: 440px) {
      margin-top: 30px;
    }
  }
  .group {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 100px;
    @media (max-width: 825px) {
      margin: 10px auto 50px auto;
      justify-content: center;
      align-items: center;
    }
  }
  .locations {
    font-size: 1.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${props => props.theme.dark ? 'var(--light60)' : 'var(--dark60)'};
    @media (max-width: 825px) {
      font-size: 1.2em;
    }
    li {
      display: inline;
      list-style-type: none;
      &:after {
        content: ' · ';
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }
`;

export const PostContainer = styled.div`
  margin: 0;
  position: relative;
  .card-img {
    max-width: 200px;
    max-height: 200px;
  }
  .image-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 10px 10px 0;
    @media (max-width: 825px) {
      width: 150px;
      height: 150px;
    }
  }
  .text-overlay {
    background-color: var(--dark80);
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    opacity: 0;
    h1 {
      font-size: 2.4em;
      margin: 0;
    }
    .date {
      text-transform: uppercase;
      font-size: 1.2em;
      margin-top: 0.8em;
    }
    &:hover {
      opacity: 1;
      transition: all 0.25s ease-in-out;
    }
    @media (max-width: 825px) {
      width: 150px;
      height: 150px;
    }
  }
`;

export const OverlayDividerContainer = styled.div`
  margin-top: 0.8em;
  width: 8%;
`;

export const ArchiveDividerContainer = styled(DividerContainer)`
  opacity: 0;
  @media (max-width: 585px) {
    opacity: 1;
  }
`;

class BlogArchive extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const group = data.allMarkdownRemark.group;
    const years = uniq(data.dates.group.map((date) => new Date(date.fieldValue).getFullYear().toString()))
      .sort((a, b) => parseInt(b) - parseInt(a));
    
    return (
      <Layout>
        <SEO
          title="All Photos"
          keywords={[`blog`, `photography`, `photos`, `react`, `gatsby`, `nyc`, `new york city`, `brooklyn`]}
        />
          <ArchiveStyles>
            { years.map(year => {
              const yearPosts = posts.filter(({node}) => node.frontmatter.photoDate.includes(year));
              const locations = uniq(yearPosts.map(({ node }) => node.frontmatter.location));
              return (
                <>
                  <div className="heading">
                    <h1>{ year }</h1>
                    <div className="locations">
                      <ul>
                        { locations.map(loc => <li>{ loc }</li>)}
                      </ul>
                    </div>
                  </div>
                  <div className="group">
                  { yearPosts.map(({ node }) => (
                    <PostContainer key={ node.fields.slug }>
                      <Link to={`/blog${node.fields.slug}`}>
                        <div className="image-container">
                          <Img
                            sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
                            alt={node.frontmatter.title}
                            className="card-img"
                          />
                        </div>
                        <div className="text-overlay">
                          <h1>{ node.frontmatter.title || node.fields.slug }</h1>
                          <OverlayDividerContainer>
                            <MoreIcon fill={ 'var(--light70' } />
                          </OverlayDividerContainer>
                          <p class="date">{ node.frontmatter.photoDate }</p>
                        </div>
                      </Link>
                      {/* <CardDate>{ node.frontmatter.date }
                      { node.frontmatter.tags && (
                        <>
                          &nbsp;&middot;<ul className="tag-list">
                            { node.frontmatter.tags && node.frontmatter.tags.map(tag => {
                              const tagSlug = kebabCase(tag);
                              return (
                                <li key={tagSlug}>
                                  <Link to={`/blog/tags/${tagSlug}`}>
                                    {tag}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </>
                      )}</CardDate> */}
                      {/* <CardBody dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }} /> */}
                    </PostContainer>
                  ))}
                  </div>
                </>
            )})}
          </ArchiveStyles>
          <ArchiveDividerContainer>
            <MoreIcon fill={ 'var(--light70' } />
          </ArchiveDividerContainer>
        <BlogFooter group={group} chrissy={data.chrissy}/>
      </Layout>
    )
  }
}

export default BlogArchive;

export const pageQuery = graphql`
  query {
    chrissy: file(relativePath: { eq: "chrissy-portfolio.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    dates: allMarkdownRemark(sort: { fields: [frontmatter___photoDate], order: DESC}) {
      group(field: frontmatter___photoDate) {
        fieldValue
      }
    }
    locations: allMarkdownRemark(sort: { fields: [frontmatter___location], order: ASC}) {
      group(field: frontmatter___location) {
        fieldValue
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            photoDate(formatString: "MMMM DD, YYYY")
            title
            description
            location
            tags
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 200, maxHeight: 200, quality: 100) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`